import {useAuth} from "../contexts/authContext";
import {useNavigate} from "react-router-dom";
import * as React from "react";
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignIn } from '@fortawesome/free-solid-svg-icons'

export default function Logout () {
    const auth = useAuth()
    const navigate = useNavigate()

    const signOut = () => {
        auth.signOut(() => {
            window.localStorage.removeItem('@token')
            navigate("/login")
        })
    }
    return (
        <Button className='bg-white border-0 text-black' onClick={signOut} variant="light">
            <FontAwesomeIcon className='fa-2x' icon={faSignIn} />
        </Button>
    );
}
