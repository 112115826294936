import Logout from "../components/Logout";
import * as React from "react";

export default function AppBar() {
    return (
        <header className='fixed-top navbar navbar-expand-lg navbar-light bg-white bg-success border-bottom'>
            <div className='container-fluid'>
                <small className="navbar-brand">
                    <strong>TAMBUE SQUARE</strong>
                </small>
                <div className="navbar-nav ms-auto">
                    <Logout />
                </div>
            </div>
        </header>
    )
}
