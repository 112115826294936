import * as React from "react"
import {PageLayout} from "../layouts"
import {Timeline} from "../components"

export default function TimelinesScreen() {
    const [list, setList] = React.useState<any[]>([])
    React.useEffect(() => {
        setList([1, 2, 3, 4, 6, 7, 8, 9, 5, 3, 78]);
    }, [])

    console.log('__timelines')
    return (
        <PageLayout title="Echéanciers du mois">
            <React.Fragment>
                {list.length > 0 && (
                    list.map((item, key) => (<Timeline key={key} title={`Timelines ${key}`} />))
                )}
            </React.Fragment>
        </PageLayout>
    );
}
