import React from "react"
import style from './Button.module.css'
type ButtonType = {
    title: string,
    theme?: string,
    size?: string,
    onClick: () => void
}

function Button({title, size = 'sm', theme = 'dark', onClick}: ButtonType) {
    return <button onClick={onClick} className={`btn btn-${size} btn-${theme} shadow`}>{title}</button>
}
export default Button
