import * as React from "react";
import {AppLayout} from "../layouts";
import {Indicator, PieChart, Welcome} from "../components";
import {faBuilding, faHandshake, faUsd} from "@fortawesome/free-solid-svg-icons";
const pieChartData = {
    labels: ['Impayés', 'Payés'],
    datasets: [
        {
            label: 'Paiements',
            data: [90, 10],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

export default function DashboardScreen() {
    console.log('__dashboard')
    return (
        <AppLayout>
            <React.Fragment>
                <Indicator title="Biens" number={'45'} info="15 biens en location" icon={faBuilding} />
                <Indicator title="Contrats" number={'15'} icon={faHandshake} />
                <Indicator title="Avoir mensuel" number={'1 500 000'} icon={faUsd} iconColor='danger' />
                <Welcome />
                <PieChart title="Statistique du mois de ..." data={pieChartData} />
                <br/>
            </React.Fragment>
        </AppLayout>
    );
}
