import * as React from "react"
import {Link} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faDashboard, faTimeline} from "@fortawesome/free-solid-svg-icons"

export default function FixedBottomNav({online = false}) {
    return (
        <div className='fixed-bottom d-flex justify-content-center border-top bg-white'>
            <div className="navbar-brand text-black btn rounded-0 w-100 ">
                <Link to='/'>
                    <FontAwesomeIcon className='fa-2x text-dark' icon={faDashboard} />
                </Link>
            </div>

            <div className="navbar-brand text-black btn rounded-0 w-100">
                <Link to='/timelines'>
                    <FontAwesomeIcon className='fa-2x text-dark' icon={faTimeline} />
                </Link>
            </div>
        </div>
    )
}
