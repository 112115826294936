import {Outlet} from "react-router-dom";
import * as React from "react";
import {AppTitleBar} from "./index";
import {StatusBar} from "../components";

type PageLayoutType = {
    title?: string,
    urlReferer?: string,
    children: any
}

export default function PageLayout({title, urlReferer, children}: PageLayoutType) {
    return (
        <div>
            <AppTitleBar title={title} />
            <div>
                <StatusBar />
                <div className='container-fluid py-5'>
                    {children}
                    <Outlet />
                </div>
            </div>
        </div>
    );
}
