import * as React from "react"
import {Routes, Route, useLocation, Navigate,} from "react-router-dom"
import AuthProvider, {useAuth} from "./contexts/authContext"
import {AuthScreen, DashboardScreen, TimelinesScreen} from './screens'

export default function App() {
  return (
      <AuthProvider>
        <Routes>
            <Route path="/login" element={<AuthScreen />} />
            <Route
                path="/"
                element={
                    <RequireAuth>
                        <DashboardScreen />
                    </RequireAuth>
                }
          />

            <Route
                path="/timelines"
                element={
                    <RequireAuth>
                        <TimelinesScreen />
                    </RequireAuth>
                }
            />
        </Routes>
      </AuthProvider>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (! auth.logged) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

