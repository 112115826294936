import * as React from "react";

export default function Welcome({username = 'LeTanou'}: {username?: string}) {
    const [time, setTime] = React.useState<string|undefined>(new Date().toLocaleTimeString())
    React.useEffect(() => {
        const interval = setInterval(() => setTime(new Date().toLocaleTimeString()), 1000)
        return () => clearInterval(interval)
    }, [time])

    return (
        <div className='small text-center my-4'>
            Hello {username}, il est {time}
        </div>
    )
}
