import {Outlet} from "react-router-dom";
import * as React from "react";
import AppBar from "./AppBar";
import {StatusBar, FixedBottomNav} from "../components";

export default function AppLayout({children}: any) {
    return (
        <div>
            <AppBar />
            <div>
                <StatusBar />
                <div className='container-fluid py-5'>
                    {children}
                    <Outlet />
                </div>
                <FixedBottomNav />
            </div>
        </div>
    );
}
