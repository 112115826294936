import * as React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons"
import {Link} from "react-router-dom"

export default function AppTitleBar({title, goBackTo} : {title: string | undefined, goBackTo?: string}) {
    return (
        <header className='fixed-top navbar navbar-expand-lg navbar-light bg-white bg-success border-bottom'>
            <div className='container-fluid'>
                <small className="navbar-brand">
                    <Link to={goBackTo || '/'}>
                        <FontAwesomeIcon className='text-dark' icon={faArrowLeft} />
                    </Link>
                    <strong className='px-3'>{title || 'App Name'}</strong>
                </small>
            </div>
        </header>
    )
}
