import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "../contexts/authContext";
import {Indicator, Timeline, InputText} from "../components";
import {faBuilding, faHandshake, faUsd} from "@fortawesome/free-solid-svg-icons";

export default function AuthScreen() {

    let navigate = useNavigate();
    let location = useLocation();
    let auth = useAuth();
    // @ts-ignore
    let from = location.state?.from?.pathname || "/";

    const [loading, isLoading] = React.useState<boolean>(false);
    const [logged, isLogged] = React.useState<boolean>(false);
    const [login, setLogin] = React.useState<string|undefined>(undefined);
    const [error, setError] = React.useState<string>('');

    React.useEffect(() => {
        if (logged) {
            isLoading(true)
            //navigate(from, { replace: true })
            window.location.reload();
        }

        return () => {}

    }, [logged])



    function handleSubmit() {
        isLoading(true)
        if (! login) {
            setError('Login required')
            isLoading(false)
        } else {
            //let token = "eyJpZCI6MiwiZmlyc3RfbmFtZSI6IlJvZ2VyIiwibGFzdF9uYW1lIjoiTE9HQSIsImNyZWF0ZWRfYXQiOiIxMVwvMDNcLzIwMjIifQ==";
            auth.signIn(login, null).then(logged => {
                //console.log('_logged', logged)
                isLogged(logged)
                if (! logged) {
                    setError('User not found')
                }
            }).catch(err => {
                setError(err.message)
            }).finally(() => isLoading(false));
        }
    }

    if (loading) {
        return <div className='text-center'>Chargement en cours ...</div>
    }

    return (
        <div className='d-flex flex-column p-2 justify-content-center vh-100'>
            <div className="col-sm-4 offset-sm-4 col-12">
                <div className='p-3 w-100'>
                    <h4 className='text-uppercase text-center'>Connectez-vous !</h4>
                    <InputText
                        label="Entrer votre code de connexion"
                        text={login}
                        uid="code"
                        onChanged={text => setLogin(text)}
                        placeholder="Ex : 1956-1"
                    />
                    {error.length > 0 && <div className='text-danger text-center small'>{error}</div>}
                    <div className='form-group my-4'>
                        <button className='btn btn-dark text-uppercase w-100 shadow' onClick={handleSubmit}>
                            Valider
                        </button>
                    </div>
                </div>
            </div>
            {/**/}
        </div>
    );
}
