import React, {useState} from "react"
import style from './Timeline.module.css'
import {Button} from "../index"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faSpinner, faCheck, faPhone} from "@fortawesome/free-solid-svg-icons"

type TimelineType = {
    title: string,
}

function Timeline({title = 'Title'}: TimelineType) {
    const [loading, isLoading] = useState<boolean>(false)
    const [updated, isUpdated] = useState<boolean>(false)

    const handle = () => {
        isLoading(true)
        setTimeout(() => {
            isUpdated(true)
            isLoading(false)
        }, 2000)
    }

    return <div className={style.Main}>
        <div className="rounded p-3 d-flex justify-content-between shadow align-items-center my-2">
            <div className={`d-flex justify-content-center align-items-center rounded-circle ${style.Phone}`}>
                <FontAwesomeIcon className={`text-muted`} icon={faPhone} />
            </div>
            <div className='w-100'>
                <div className="mb-1 text-dark">
                    {title}
                </div>
                <div className={`text-uppercase ${style.Info}`}>Appartement 201, Ndokoti</div>
                <div className={`text-muted ${style.Info}`}>250 000 avant le 15/12/2023</div>
            </div>
            <div className='w-50 d-flex justify-content-end'>
                {! updated && (
                    <div>
                        {! loading && <Button theme='success' title="Encaisser" onClick={handle} /> }
                        {loading && <FontAwesomeIcon className={`text-muted spinner`} icon={faSpinner} /> }
                    </div>
                )}
                {updated && <FontAwesomeIcon className={`text-success`} icon={faCheck} />}
            </div>
        </div>
        {updated && <div className='small text-success text-center'>L'écheancier à été mis à jour avec success</div>}
    </div>
}
export default Timeline
