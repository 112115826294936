import * as React from "react";
import {useAuth} from "../contexts/authContext";

export default function StatusBar() {
    const {online} = useAuth();
    return (
        <div>
            {! online && (
                <div className='bg-warning text-center small'>
                    Vous n'êtes pas connecté à internet.
                </div>
            )}
        </div>
    )
}
