import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

// https://codesandbox.io/s/github/reactchartjs/react-chartjs-2/tree/master/sandboxes/pie/default?from-embed=&file=/App.tsx
ChartJS.register(ArcElement, Tooltip, Legend);

type PieChartType = {
    title: string,
    data: any
}

export default function PieChart({title, data}: PieChartType) {
    return <React.Fragment>
        <h4 className='text-center text-uppercase'>{title}</h4>
        <Pie data={data} />
    </React.Fragment>;
}
