import * as React from "react";
import {fakeAuthProvider} from "../auth";
import {useNavigate, Routes} from "react-router-dom";
import {decode} from 'js-base64';
import {remotePost} from "../remotes/api";

interface AuthContextType {
    online?: boolean;
    logged?: boolean;
    token: string | undefined;
    user: any;
    signIn: (login: string, password?: string | null) => Promise<any>;
    signOut: (callback: VoidFunction) => void;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export default function AuthProvider({ children }: { children: React.ReactNode }) {

    const [token, setToken] = React.useState<string | undefined>(undefined);
    const [user, setUser] = React.useState<any>(null);
    const [online, isOnline] = React.useState<boolean>(true);
    const [loading, isLoading] = React.useState<boolean>(false);
    const [logged, isLogged] = React.useState<boolean>(false);

    const navigate = useNavigate();

    React.useEffect(() => {
        const token: string = window.localStorage.getItem('@token') as string

        if (token) {
            setToken(token)
            setUser(true)
            isLogged(true)
            console.log('_token', token)
            navigate('/', { replace: true })
        }

    }, [loading])

    const signIn = (login: string, password?: string | null) => {
        return remotePost('https://jsonplaceholder.typicode.com/posts', {login, password}).then(data => {

            setToken(login);
            setUser(true);
            window.localStorage.setItem('@token', login)
            console.log(data)
            return true;
        })
    };

    const signOut = (callback: VoidFunction) => {
        return fakeAuthProvider.logout(() => {
            setToken(undefined);
            setUser({});
            callback();
        });
    };

    const value = { token, user, signIn, signOut, online, logged, isLoading, setToken, setUser};

    if (loading) {
        return <p>Loading ...</p>
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return React.useContext(AuthContext);
}
