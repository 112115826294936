import React from "react"
import style from './Indicator.module.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
type IndicatorType = {
    title: string,
    number: string,
    info?: string,
    iconColor?: string,
    icon: any
}

function Indicator({title, number, info, icon, iconColor = 'dark'}: IndicatorType) {
    return <div className={style.Indicator}>
        <div className="rounded shadow h-100 px-2 my-3">
            <div className="card-body">
                <div className="row align-items-center text-uppercase">
                    <div className="col mr-2">
                        <div className="font-weight-bold mb-1">
                            <strong>{title}</strong>
                        </div>
                        <div className="h5">
                            {number}
                        </div>
                        <div className={`text-muted ${style.MoreInfo}`}>
                            {info}
                        </div>
                    </div>
                    <div className="col-auto">
                        <FontAwesomeIcon className={`fa-2x text-${iconColor}`} icon={icon} />
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default Indicator
