import React, {useState} from "react";

type InputTextType = {
    label: string | undefined;
    text: string | undefined;
    uid: string | undefined;
    placeholder?: string | undefined;
    onChanged: (text: string) => void
}

export default function InputText({label, text, onChanged, uid, placeholder}: InputTextType) {
    const [value, setValue] = useState<string | undefined>(text)

    const handleChanged = (inputText: string) => {
        setValue(inputText)
        onChanged(inputText)
    }
    return (
        <div className='form-group'>
            <label htmlFor={uid}>
                {label}
            </label>
            <div className="input-group">
                <input
                   type="number"
                   id={uid}
                   className="form-control text-right"
                   placeholder={placeholder}
                   onChange={event => handleChanged(event.target.value)}
                />
            </div>
        </div>
    )
}
